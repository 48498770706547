<template>
  <div class="follow">
    <van-pull-refresh v-model="refresh" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t.noMoreData"
        @load="onLoad"
      >
        <van-sticky class="follow-sticky">
          <van-row type="flex" align="center" class="tabList">
            <van-col class="tabItem" @click="changeType(0)">
              <section
                :class="['itemTop', active === 0 ? 'itemTopAction' : '']"
              >
                <p class="itemTopName">{{ $t.meta.followPage }}</p>
              </section>
              <div
                :class="['itemBorder', active === 0 ? 'itemBorderAction' : '']"
              ></div>
            </van-col>
            <van-col class="tabItem" @click="changeType(1)">
              <section
                :class="['itemTop', active === 1 ? 'itemTopAction' : '']"
              >
                <p class="itemTopName">{{ $t.meta.sharedPage }}</p>
              </section>
              <div
                :class="['itemBorder', active === 1 ? 'itemBorderAction' : '']"
              ></div>
            </van-col>
          </van-row>
        </van-sticky>

        <div class="list">
          <follow-list :listFollow="listFollow"></follow-list>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import Vue from "vue";
import { Sticky, Row, Col, List } from "vant";
import { PullRefresh } from "vant";
Vue.use(Sticky).use(Row).use(List).use(Col);
Vue.use(List).use(Sticky);
import FollowList from "./components/followList";

export default {
  name: "Follow",
  components: {
    FollowList,
    "van-pull-refresh": PullRefresh,
  },
  data() {
    return {
      active: 0,
      listFollow: [], //产品
      type: 1,
      pageIndex: 1, //页码,
      refresh: false,
      loading: true,
      finished: false,
    };
  },
  computed: {},
  mounted() {
    this.getFollowProductByPage();
  },
  methods: {
    changeType(index) {
      if (index == 1) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "focus/postedProduct",
          name: "postedProduct",
          query: [],
        });
      }
    },
    onRefresh() {
      this.refresh = true;
      this.getFollowProductByPage(true)
        .then(() => {
          this.refresh = false;
        })
        .catch(() => {
          this.refresh = false;
        });
    },
    getFollowProductByPage(hideLoading = false) {
      return new Promise((resolve, reject) => {
        if (!hideLoading) {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
        }
        let param = {};
        if (this.type !== 0 && this.type !== 1) {
          param = {
            PageIndex: 1,
          };
        } else {
          param = {
            PageIndex: 1,
            ScreenValues: this.type,
          };
        }
        this.$api.follow
          .loadFollowProductByPage(param)
          .then((res) => {
            const data = res.data;
            this.listFollow = data;
            if (data === "" || data === null || data.length === 0) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.loading = false;
            if (!hideLoading) {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.hideLoading
              );
            }
            resolve();
          })
          .catch((error) => {
            this.loading = false;
            if (!hideLoading) {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.hideLoading
              );
            }
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showDialog,
              {
                msg: error.message,
                status: error.status,
              }
            );
            reject();
          });
      });
    },
    //加载更多
    onLoad() {
      let param = {};
      this.finished = false;
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: this.pageIndex + 1,
        };
      } else {
        param = {
          PageIndex: this.pageIndex + 1,
          ScreenValues: this.type,
        };
      }
      this.$api.follow
        .loadFollowProductByPage(param)
        .then((res) => {
          const data = res.data;
          if (data === "" || data === null || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.listFollow = this.listFollow.concat(data);
            this.pageIndex = this.pageIndex + 1;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.follow {
  width: 100%;
  font-size: 26px;
  .van-pull-refresh {
    overflow: unset;
  }
  .list {
    width: 100%;
  }
  .follow-sticky {
    background: -webkit-linear-gradient(left, #57c88c, #2ea897);
    ::v-deep .van-sticky {
      background: -webkit-linear-gradient(left, #57c88c, #2ea897);
    }
    .tabList {
      width: 100%;
      padding-top: 18px;
      text-align: center;
      background: -webkit-linear-gradient(left, #57c88c, #2ea897);
      margin-top: env(safe-area-inset-top);
      .tabItem {
        width: 50%;
        text-align: center;
        color: #fff;
        .tabItemTotal {
          margin: 0;
          padding: 0;
        }
        .itemTop {
          @include puiblicFlex;
          text-align: center;
          justify-content: center;
          -webkit-justify-content: center;
          .itemTopName {
            font-size: 16px;
            margin: 0;
            padding: 0;
          }
          .itemTopNum {
            margin: 0;
            padding: 0;
          }
        }
        .itemBorder {
          height: 4px;
          width: 30px;
          // background-color: #43cd9a;
          margin: 0 auto;
          border-radius: 16px;
          margin-top: 10px;
          opacity: 0;
        }
        .itemBorderAction {
          background-color: #fff;
          opacity: 1;
        }
      }
    }
  }
}
</style>
